(function() {
    'use strict';
    angular
        .module('platformApp')
        .factory('History', History)
        .factory('ExpressHistory', ExpressHistory)
        .factory('SalesOrderPayConfig', SalesOrderPayConfig)
        .factory('SalesOrder', SalesOrder);

    SalesOrder.$inject = ['$resource'];

    History.$inject = ['$resource'];

    ExpressHistory.$inject = ['$resource'];



    SalesOrderPayConfig.$inject = ['$resource']


    function SalesOrderPayConfig($resource) {
        var resourceUrl = 'manager/' + 'api/wechat/:path';
        return $resource(resourceUrl, {}, {
            "partiallyRepayWechatQRPay":{
                method:"GET",
                params:{
                    path:"partiallyRepayWechatQRPay"
                },
            },
        });
    }
 
    function ExpressHistory ($resource) {
        var resourceUrl =  'service/' + 'api/order-express-histories/:path';
        return $resource(resourceUrl, {}, {
            'byOrderId':{
                method: 'GET',
                params: {
                    path: 'byOrderId'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            }
        });
    }


    function History ($resource) {
        var resourceUrl =  'service/' + 'api/histories/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'pageByCondition': {
                method: 'POST',
                params: {
                    path: 'pageByCondition'
                },
                isArray: true
            }
            // ----------销售分析使用----end-------
        });
    }


    function SalesOrder ($resource) {
        var resourceUrl =  'service/' + 'api/sales-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'updateExpress': {
                method: 'POST',
                params: {
                    path: 'updateExpress'
                }
            },
            'updateByHandlerExpress':{
                method: 'GET',
                    params: {
                    path: 'updateByHandlerExpress'
                }
            },
            'byStatus': {
                method: 'GET',
                isArray: true,
                params: {
                    path: 'byStatus'
                }
            },
            'getStatusData': {
                method: 'GET',
                params: {
                    path: 'getStatusData'
                }
            },
            'byConditionPage': {
                method: 'GET',
                params: {
                    path: 'byConditionPage'
                }
            },
            'postForOrder': {
                method: 'GET',
                params: {
                    path: 'postForOrder'
                }
            },
            "supportReject":{
                method: 'GET',
                params: {
                    path: 'supportReject'
                }
            },
            'doCheck': {
                method: 'GET',
                params: {
                    path: 'doCheck'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition3'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "customerServiceMessage":{
                method:"POST",
                params:{
                    path:"customerServiceMessage"
                },
            },
            "updateDelivery":{
                method:"POST",
                params:{
                    path:"updateDelivery"
                }
            },
            "delivery":{
                method:"GET",
                params:{
                    path:"delivery"
                }
            },
            "jumpJustify":{
                method:"GET",
                params:{
                    path:"jumpJustify"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getHomeNum2': {
                method: 'GET',
                params: {
                    path: 'getHomeNum2'
                },
                isArray: true
            },
            "getHomePayAmount":{
                method:"GET",
                params:{
                    path:"getHomePayAmount"
                }
            },
            "getHome2Map":{
                method:"GET",
                params:{
                    path:"getHome2Map"
                }
            },
            "getHomeProduct": {
                method:"GET",
                params: {
                    path:"getHome2Product"
                }
            },
            // ----------销售分析使用-----------
            "getOrderNumAndSalesNum": {
                method: "GET",
                params: {
                    path: "getOrderNumAndSalesNum"
                }
            },
            "getOrderNums": {
                method: "GET",
                params: {
                    path: "getOrderNums"
                }
            },
            "getSalesNums": {
                method: "GET",
                params: {
                    path: "getSalesNums"
                }
            },
            "downloadFile": {
                method: "GET",
                params: {
                    path: "downloadFile"
                }
            },
            "productAnalysis": {
                method: "GET",
                params: {
                    path: "productAnalysis"
                }
            },
            "productXmlAnalysis": {
                method: "GET",
                params: {
                    path: "productXmlAnalysis"
                }
            },
            "productAnalysisDownload": {
                method: "GET",
                params: {
                    path: "productAnalysisDownload"
                }
            },
            // ----------销售分析使用----end-------
            "reJustify": {
                method: "GET",
                params: {
                    path: "reJusify"
                }
            },
            "yesterdayDate": {
                method: "GET",
                params: {
                    path: "yesterdayDate"
                }
            },
            "dateList": {
                method: "GET",
                params: {
                    path: "DateList"
                }
            },
            "getListPage": {
                method: "GET",
                params: {
                    path: "getListPage"
                }
            },
            "getListPage2": {
                method: "GET",
                params: {
                    path: "getListPage2"
                }
            },
            "reject": {
                method: "GET",
                params: {
                    path: "reject"
                }
            },
            "rejects": {
                method: "GET",
                params: {
                    path: "rejects"
                }
            },
            "getAllNumByState": {
                method: 'GET',
                params: {
                    path: 'getAllNumByState'
                }
            },
            "justify": {
                method: "GET",
                params: {
                    path: "justify"
                }
            },
            "approveRefund": {
                method: "GET",
                params: {
                    path: "approveRefund"
                }
            },
            "rejectRefund": {
                method: "GET",
                params: {
                    path: "rejectRefund"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'fixed': {
                method: 'GET',
                params: {
                    path: 'fixed'
                }
            },
            'recompose': {
                method: 'GET',
                params: {
                    path: 'recompose'
                }
            },
            'reJustify': {
                method: 'GET',
                params: {
                    path: 'reJustify'
                }
            },
            'forceCheck': {
                method: 'GET',
                params: {
                    path: 'forceCheck'
                }
            }
        });
    }
})();
